<template>
    <div class="container section">
        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
        <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>
        <div class="row lined"  v-show="!lease.Upload.id">
            <div class="col-xs-12 col-sm-4">
                <label>Choose Lease</label>
            </div>
            <div class="col-xs-12 col-sm-8">
                <dropdown
                        :list="documentList"
                        :value="getDocumentName"
                        @input="setDocument"
                        hasEmpty
                ></dropdown>
                &nbsp;&nbsp;
                <button @click="createLease" class="btn btn-primary" v-show="document.id">Use this Lease </button>
                <span v-show="isLoading($options.name)" ><loader color="#00b2ce" size="20px" class="inline-loader"></loader></span>
            </div>
        </div>
        <div class="lease-section"  v-show="lease.Upload.id">
            <div class="row lined">
                <div class="col-xs-12">
                    <h2>Your lease has been created.</h2>
                </div>

            </div>
            <div class="row">
                <div class="col-xs-6">View or print this lease. Opens in a new tab.</div>
                <div class="col-xs-6 text-right"><a :href="lease.Upload.src" target="_blank" class="btn btn-primary">View / Print</a></div>
            </div>

            <div class="row" v-show="!signersSetUp">
                <div class="col-xs-6">Set up electronic Signatures</div>
                <div class="col-xs-6 text-right">
                    <button @click="setUpSigning()" class="btn btn-primary"><i class="fa fa-gear"></i> &nbsp;Configure</button>
                </div>
            </div>

            <div class="row" v-show="signersSetUp">
                <div class="col-xs-12 lined" v-for="t in lease.Tenants">
                    <div class="col-xs-6">
                    {{t.User.first}} {{t.User.last}}
                    </div>
                    <div class="col-xs-6 text-right">
                        {{ t | signStage }}&nbsp;&nbsp;
                        <a @click.prevent="signLease(t)" class="btn btn-sm btn-std" v-show="t.stage && t.stage != 'CONFIRM'"><strong>Sign Now</strong></a>
                        <a @click.prevent="sendSignLink(t)" class="btn btn-sm btn-std" v-show="t.stage && t.stage != 'CONFIRM'"><strong>Email Link</strong></a>
                    </div>
                </div>

                <!--
                <div class="col-xs-12" v-show="allSigned">
                    Email link to tenants to sign.  A link will be sent so that tenants can sign remotely
                    <a href="#" @click.prevent="setUpSigning('email')" class="btn btn-primary">Email link to sign</a>
                </div>
                -->
            </div>
        </div>

        <modal v-if="signLeaseModal == true" allow-click-away="false">
            <h3 slot="header">Who should sign this lease?</h3>
            <div slot="body">
                <span v-show="loadingTenants" ><loader color="#00b2ce" size="20px" class="inline-loader"></loader></span>
                <status @resetStatus="errorClear('SigningError')" v-if="errorHas('SigningError')" :message="errorGet('SigningError')" status="error"></status>
                <div class="row">
                    <div class="col-xs-12">
                        <p>Choose the tenants who are required to sign this document, the click next</p>
                    </div>
                </div>

                <div class="row lined " v-for="t, i in lease.Tenants" :key="t.id">
                    <div class="col-xs-8 checkbox sm">
                        <label>
                            <input v-model="signers" type="checkbox" :value="t.id" />
                            {{t.first}} {{t.last}}
                        </label>
                    </div>
                    <div class="col-xs-3 text-right signer-order" v-show="signers.indexOf(t.id) >= 0">
                        Signer #{{signingOrder.indexOf(t.id) + 1}}
                    </div>
                </div>


                <div class="row">
                    <div class="col-xs-11 checkbox info-section">
                        <label>
                            <input type="checkbox" v-model="sendEmail" />
                            Send and email to selected tenants so they can sign electronically
                        </label>
                    </div>
                </div>


                <div class="modal-footer">
                    <button class="btn btn-clear" @click="signLeaseModal = false">Close</button>
                    <button class="btn btn-dark" @click="setSigningOrder">Next</button>
                </div>

            </div>
        </modal>

    </div>
</template>

<script type="text/babel">
    import api from '../../assets/api.js';
    import Loader from '../assets/CircleSpinner.vue';
    import Status from '../includes/Messages.vue';
    import Dropdown from '../assets/Dropdown.vue';
    import Modal from '../assets/Modal.vue';

    import draggable from 'vuedraggable';


    export default {
        name: "GenerateLease",
        data() {
            return {
                sendEmail: false,
                loadingTenants: false,
                signLeaseModal: false,
                documents:[],
                document: {
                    id: '',
                    name: ''
                },
                lease: {
                    Upload: {},
                    Tenants:[]
                },
                signers:[],
                error: '',
                sign_type: ''
            }
        },
        computed:{
            allSigned(){
                return this.lease.Tenants.filter(function(t){
                    return t.stage == 'SIGNED' || !t.stage;
                }).length == 0
            },
            signersSetUp(){
                return this.lease.Tenants.filter(function(t){
                    return t.stage;
                }).length
            },
            signingOrder(){
                var _this = this;
                return this.lease.Tenants.filter(function(t){
                    return _this.signers.indexOf(t.id) >=0;
                }).map(function(t){
                    return t.id
                })
            },
            documentList(){
                if(!this.documents.length) return [];
                return this.documents.map(function(d){
                    return d.name;
                })
            },
            getDocumentName(){
                var _this = this;
                return this.documents.filter(function(d){
                    return d.id == _this.document.id;
                }).map(function(d){
                    return d.name;
                })[0]
            }
        },
        mounted (){
            this.fetchLeaseDocuments();
            this.fetchLease();
        },
        components: {
            Dropdown,
            Status,
            Loader,
            Modal,
            draggable
        },
        filters:{
            signStage(tenant){
                if(!tenant.stage) return 'Not Signing';
                if(tenant.stage == 'REQUEST SIGNING') return 'Not Yet Responded';
                if(tenant.stage == 'CONSENT TO ELECTRONIC SIGN') return "In Progress";
                if(tenant.stage == 'CONFIRM'){
                    console.log(tenant.created_at);
                    var created =  moment.utc(tenant.created_at);
                    return "Signed on " + created.local().format('l hh:MM:SS');
                }
            }
        },
        methods:{

            setUpSigning(type){
                this.signLeaseModal = true;
            },

            setSigningOrder(){
                var order = this.signingOrder;
                if(order.length < 1){
                    this.errorSet("SigningError", "Please select at lease one person to sign this lease");
                    return;
                }

                var data = {
                    signers: order,
                    lease_id: this.lease.Upload.id
                };

                api.post(this, api.SET_UP_SIGNERS, data, "SigningError").then(r => {
                    if(this.sendEmail){
                        return this.sendSignLink();
                    }
                    return true;
                }).then(function(){
                    this.signLeaseModal = false;
                    this.fetchLease();
                });

            },

            signLease(t){
                var data = {
                    tenants: [{
                        id: t.id
                    }],
                    upload_id: this.lease.Upload.id
                };

                api.post(this, api.GET_SIGN_LINK, data, "SignLease").then(r => {
                    window.open('/sign-documents/' + r.hash, '_blank');
                });
            },
            sendSignLink(t){
                var tenants = [];
                if(!t){
                    tenants = this.signingOrder
                } else {
                    tenants.push(t.id);
                }

                var data = {
                    tenants: tenants,
                    upload_id: this.lease.Upload.id
                };

                api.post(this, api.SEND_SIGN_LINK, data);

            },

            setDocument(documentName){

                if(!documentName){
                    this.document = {
                        id: '',
                        name: ''
                    }
                    return false;
                }
                this.document = this.documents.filter(function(d){
                    return d.name == documentName;
                })[0];

            },
            fetchLeaseDocuments(){
                var data = {
                    type: 'lease'
                };
                api.get(this, api.DOCUMENT, data).then(r => {
                    this.documents = r.documents;
                });
            },

            fetchLease(){
                api.get(this, api.UPLOADS_GET_LEASE + this.lease_id).then(r => {

                    this.lease = r.lease || {};
                });
            },

            generateLease(){
                this.createLease();
            },


            createLease(){
                var data = {
                    lease_id: this.lease_id,
                    document_id: this.document.id
                };

                api.post(this, api.GENERATE_DOCUMENT, data).then(r => {
                    this.lease.Upload = r.upload;
                    this.$emit('saved');
                    this.fetchLease();
                });

            }
        },
        props: ['lease_id']
    }
</script>

<style>
    .sort-container.row  .signer-order{
        padding: 10px 10px 0 10px;
        color: #a0a0a0;

    }
    .handle {
        float: left;
        padding: 10px 10px 0 10px;
        cursor: hand;
        color: #a0a0a0;
    }
    .sort-container.row .lined > .row {
        padding-top: 0;
        padding-bottom: 0;
    }
</style>