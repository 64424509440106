<template>
  <div>
    <div class="pt-3">
      <v-menu v-model="showFilters" :disabled="isMenuDisabled" :close-on-content-click="false" offset-y content-class="hb-elevation-large">
          <template v-slot:activator="{ on }">
              <div v-on="on" class="d-flex align-center pl-4 pr-2 select-filter" :class="{ 'download-center-filters-active' : showFilters }">
                <span class="truncate">{{getDocumentMethod.text}}</span>
                <v-spacer></v-spacer>
                <hb-icon color="#637381">mdi-filter-variant</hb-icon>
              </div>
          </template>

          <v-card width="300">
            <v-card-text class="pa-4" style="overflow: auto;">
              <div class="hb-text-light hb-default-font-size font-weight-medium">Document Type</div>
              <v-select
                :items="DOCUMENT_METHOD"
                item-text="text"
                item-value="value"
                v-model="documentMethod"
                background-color="white"
                hide-details
                single-line
                label="Select"
                :menu-props="{ bottom: true, offsetY: true, contentClass: 'hb-elevation-large' }"
                id="documentType"
                class="pt-0 pb-2"
              >
              </v-select>
              <v-radio-group class="ma-0 pa-0 pb-1" hide-details v-model="documentType" v-for="(item, index) in getDocumentType" :key="index + '_' + getDocumentMethod.text">
                <hb-radio class="mt-2" :label="item.name === 'Autopay' ? 'AutoPay' : item.name" :value="item"></hb-radio>
              </v-radio-group>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="px-4 py-3" style="background: linear-gradient(180deg, #fafbfc 0%, #ebeff2 100%);">
              <hb-link color="tertiary" @click="resetFilters">Reset</hb-link>
              <v-spacer></v-spacer>
              <hb-btn small @click="applyFilters">Apply</hb-btn>
            </v-card-actions>
          </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script type="text/babel">
import { mapGetters } from 'vuex';
export default {
  name: "DocumentsFilter",
  data() {
    return {
      DOCUMENT_METHOD: [
        { text: 'Signature Documents', value: 'signed' },
        { text: 'Non Signature Documents', value: 'un-signed' },
      ],
      DOCUMENT_TYPE: {
        signed: [
          { name: 'Select All', value: 'all' },
          { name: 'Autopay', value: 'autopay' },
          { name: 'Coverage', value: 'coverage' },
          { name: 'Coverage Declined', value: 'coverage-declined' },
          { name: 'Military', value: 'military' },
          { name: 'Other', value: 'other' },
        ],
        'un-signed': [
          { name: 'Select All', value: 'all' },
          { name: 'Rent Change', value: 'rent-change' },
          { name: 'Delinquency', value: 'delinquency' },
          { name: 'Reversal', value: 'reversal' },
          { name: 'Other', value: 'other' },
        ],
      },
      documentMethod: 'un-signed',
      documentType: { value: 'all' },
      showFilters: false
    };
  },
  props: [ 'isDocumentReset', 'isMenuDisabled' ],
  async created() {
    if(this.getDocumentTypes.length ) {
      this.DOCUMENT_TYPE['signed'] = JSON.parse(JSON.stringify(this.getDocumentTypes));
      if(this.DOCUMENT_TYPE['signed'][0].value != 'all') {
        this.DOCUMENT_TYPE['signed'].unshift({ name: 'Select All', value: 'all' });
      }
    }
    if(this.getUnSignedDocumentTypes.length) {
      this.DOCUMENT_TYPE['un-signed'] = JSON.parse(JSON.stringify(this.getUnSignedDocumentTypes));
      if(this.DOCUMENT_TYPE['un-signed'][0].value != 'all') {
        this.DOCUMENT_TYPE['un-signed'].unshift({ name: 'Select All', value: 'all' });
      }
    }
    this.resetFilters();
  },
  computed: {
    ...mapGetters({
      getDocumentTypes: 'documentsStore/getDocumentTypes',
      getUnSignedDocumentTypes: 'documentsStore/getUnSignedDocumentTypes'
    }), 
    getDocumentMethod() {
      return this.DOCUMENT_METHOD.find(item => item.value == this.documentMethod);
    },
    getDocumentType() {
      return this.DOCUMENT_TYPE[this.getDocumentMethod.value];
    },
  },
  methods: {
    applyFilters() {
      this.showFilters = false;
      this.isTypeAccordingToMethod();
      let payload = {
        method: this.documentMethod,
        type: this.documentType
      }
      this.$emit('applyFilters', payload);
    },
    resetFilters() {
      this.documentMethod = "un-signed";
      this.documentType = { value: 'all' };
      this.applyFilters();
    },
    isTypeAccordingToMethod (){
      let isDocumentChanged = true
      this.DOCUMENT_TYPE[this.documentMethod].forEach((element, index) => {
        if(index !== 0){
          if(this.documentType.value === element.value) {
            isDocumentChanged = false
          }
        }
      });
      if(isDocumentChanged){
        this.documentType = { value: 'all' };
      }
    },
  },
  watch: {
    isDocumentReset(val) {
      if(val) {
        this.documentType = { value: 'all' };
        let payload = {
          method: this.documentMethod,
          type: this.documentType
        }
        this.$emit('applyFilters', payload);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.truncate {
  display: inline-block;
  vertical-align: middle;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>